.Parallax{
    position: relative;
    background-image: url("../../assets/images/Imagem4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
    overflow: hidden;
    background-attachment: fixed; 
    background-position: center center;
    background-position: 50% 50%;
    padding: 40px;
    color: #000;
}
.Parallax::after{
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 8;
}
.Container{
    width: 100vw;
    height: 80vh;
    
}
.TitleObra {
    padding: 45px;
    text-align: center;
    color:  #000;
    font-size: 35px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}
