.Image{
    width: 200px;
    height: 150px;
}

.About {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-around;
    padding: 35px;
    width: 100%;
    margin: 0 auto;
    top: 40px;
    gap: 40px;
  
}
.HistorySubtitle{
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    letter-spacing: 1px;
    font-weight: bold;
}
.History {
    font-family: "Lato", sans-serif;
    line-height: 130%;
    letter-spacing: 1px;
    text-align: justify;
    font-size: 1.3rem;
    font-weight: 500;
}
.TitleAbout{
    text-align: center;
    font-family: "Roboto", sans-serif ;
    font-weight:    bold  ;
    padding: 10px;
    font-size: 35px;
}
.Wrapper{
    display: flex;
    justify-content: space-between ;
    padding: 20px;
    flex-wrap: wrap;
    align-items: center;
}
.AboutHistory{
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 20px;
    gap: 15px;
}
.Logo img{
    position: relative;
    width: 210px;
    height: 210px;
    right: 40px;
}
@media(max-width:710px){

    .Wrapper{
        justify-content: center;
    }
    .History{
        line-height: 100%;
    }
}
@media(max-width:550px){
    .History{
        line-height: 100%;
        letter-spacing: 0;
    }
    .TitleAbout{
        font-size: 25px;
    }
    .AboutHistory{
        width: 100%;
    }
    .Logo img{
        right: 0;
    }
}