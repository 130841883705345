header.Parallax.Header{
    background-image: url("../../assets/images/fundo.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
    overflow: hidden;
    background-attachment: fixed; 
    background-position: center center;
    background-position: 50% 50%;

}
.Title{
    position: relative;
    display: flex;
    align-items: center;
    color: #FFF;
    font-family: 'Ubuntu', sans-serif;
    font-size: 40px;
    font-weight: bold;
    top: 32px;
}
.Header.NavList{
    display: block;
}
.NavList{
    position: relative;
    display: flex;
    flex-direction: row;
    color: #fff;
    padding: 10px;
    justify-content: end;
    gap: 25px;
    align-items: flex-end;
    list-style: none;
    font-family: 'Ubuntu', sans-serif;
    font-size:25px;
    font-weight: 600;
    cursor: pointer;
    bottom:140px;
    text-decoration: none;
}

.NavList li:hover{

    border-bottom: 2px solid #fff;
}
.SectionTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: #fff;
    position: relative;
    top: 300px;
    gap: 10px;
}
.Logo_Header{
    width:160px;
    height: 160px;
}
.Header::after{
    width: 100%;
    height: 100%;
    z-index: 8;
}
.NavList a{
    text-decoration: none;
    color: #fff;
}
@media(max-width:500px){
    .Title{
        font-size: 25px;
    }
    .NavList{
        font-size: 18px;
        justify-content: center;
        bottom: 100px;
        align-items: center;
    }
    .NavList a{
        color: #333030;
    }
    .Logo_Header{
        position: relative;
        width: 120px;
        height: 120px;
    }
}
