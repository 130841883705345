.Footer{
    position: relative;
    background: rgb(28,27,29);
    background: linear-gradient(90deg, rgba(28,27,29,1) 0%, rgba(13,13,14,1) 100%, rgba(205,212,213,1) 100%);
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 80px;
    font-family: "Roboto", sans-serif;
    top: 210px;
}
.Mapa{
 width:500px;
 height: 200px;   
}
.Icon{
    width: 50px;
    height: 50px;
    color:  #fff;
}
.TitleIcons{
    color: #fff;
    font-size: 32px;
    text-align: center;
    padding: 5px;
}
.Section-icons{
    padding: 15px;
}
.IconContainer{
    display: flex;
    flex-direction: row;
    gap: 30px;
    text-align: center;
}
.Icon_text{
    text-align: center;
    color: #fff;
}
@media(max-width:750px){
    .Mapa{
        width: 280px;
        height: 200px;
    }
}
@media(max-width:470px){
    .Footer{
        padding: 0;
        gap: 0;
    }
}
@media(max-width:400px){
    .Mapa{
        width: 100%;
        height: 120px;
    }
}